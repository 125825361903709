import Box from "@mui/material/Box";
import Blockquote from "components/Blockquote";
import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import kevinImg from "src/images/community/kevin-bremond.jpg";
import sigImg from "src/images/community/wendy-sig.png";
import wendyImg from "src/images/community/wendy.jpg";
export default function WendysRetirementPage() {
  return (
    <BlogLayout slug="wendys-retirement">
      <Blockquote sx={{ mb: 7 }}>
        <Box
          component="img"
          src={kevinImg}
          sx={{ width: "25%", float: "right" }}
          ml={2}
          mb={2}
        />
        <Paragraph>
          My name is Kevin Bremond, I am the Chair of the East Oakland Community
          Project&rsquo;s (EOCP) Board of Directors.&nbsp; I am writing to
          acknowledge the incredible work of our Executive Director, Wendy
          Jackson, and to announce her retirement.&nbsp; Wendy has been the
          backbone and fearless leader of EOCP for over 26 years.&nbsp;
          EOCP&rsquo;s Board of Directors extends our sincere gratitude to Wendy
          for her service and unwavering commitment to fighting for people who
          experience homelessness.&nbsp;
        </Paragraph>

        <Paragraph>
          The EOCP Board has been meeting for several months to assess the needs
          of the organization during this time of transition.&nbsp; We have
          established a Succession Planning Committee that meets regularly to
          ensure a seamless transition for EOCP&rsquo;s clients, staff,
          partners, stakeholders, and funders.
        </Paragraph>

        <Paragraph>
          Please join me in thanking, celebrating, and honoring the Wonderful
          Wendy Jackson for her service.
        </Paragraph>

        <Paragraph>Sincerely,</Paragraph>

        <Paragraph>
          Kevin Bremond
          <br />
          <em>EOCP Board Chair&nbsp;</em>
        </Paragraph>
      </Blockquote>

      <Box
        component="img"
        src={wendyImg}
        sx={{ width: "50%", float: "left" }}
        mr={3}
        mb={3}
      />
      <Paragraph>Dear Friends:</Paragraph>

      <Paragraph>
        After 26 years as Executive Director I will be retiring from the East
        Oakland Community Project (EOCP). As I look forward to encouraging my
        seven-year old granddaughter to read every night and help her brother
        shape his words, I am moved to remember some of the outstanding
        victories and challenges faced by my other family, EOCP.
      </Paragraph>

      <Paragraph>
        We have come a long way from an organization that was basically bankrupt
        in 1996 to a $10+ million operation that is currently a vital community
        resource. I am grateful to all of you who work tirelessly to serve,
        collaborate with and support our efforts to assist people experiencing
        homelessness in Oakland and Alameda County. When I joined EOCP, we only
        operated a shelter that served three &ldquo;hots and a cot&rdquo; in a
        dilapidated and uninhabitable warehouse. Currently, we offer three
        additional residential programs beyond Crossroads as well as six rapid
        rehousing initiatives and medical respite.
      </Paragraph>

      <Paragraph>
        We also dug deeper to create compassionate and comprehensive services
        that would help our community move towards well-being. Life skills and
        holistic case management provides our residents an opportunity for
        stability. Our programming would not be relevant without hiring from the
        community we serve, a practice that we continue to this day. With better
        programs and outcomes, it became apparent that the dilapidated shelter
        was holding our residents back.
      </Paragraph>

      <Paragraph>
        Over a period of time, we knocked upon many doors to garner the will and
        funding to build a new shelter that would provide dignified emergency
        housing for our residents. In 2008, we successfully opened Crossroads, a
        green, state of the art facility that offers a healthy environment for
        our clients. Crossroads is not only an important resource in the
        community but it is here to stay as EOCP paid off its mortgage in 2019.
        More recently, with great support from the City of Oakland, EOCP
        launched its 20-family shelter, Family Matters.
      </Paragraph>

      <Paragraph>
        I know that we could have never done this without the help of many
        others. We have benefitted from community champions for change, our
        steadfast City and County funding sources, donors and collaborating
        organizations who have supported us through almost three decades of
        work. I am especially indebted to those who have passed away, whose
        specific contributions to EOCP and our clients went above and beyond.
      </Paragraph>

      <Paragraph>
        We are at a critical point in our history where we need new thinking and
        efforts to live our mission and values and to strengthen what we have. I
        am working closely with our Board of Directors to ensure a smooth
        transition. I look forward to EOCP&rsquo;s future with hope and
        gratefulness.
      </Paragraph>

      <Paragraph>&nbsp;</Paragraph>

      <Paragraph>
        Sincerely,
        <br />
        <img src={sigImg} style={{ height: 38, width: 200 }} />
        <br />
        Wendy U. Jackson
        <br />
        Executive Director
      </Paragraph>
    </BlogLayout>
  );
}

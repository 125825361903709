import Container from "src/components/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import InvertBox from "src/components/InvertBox";

export default function Blockquote({
  children,
  invert,
  my = 3,
  overline,
  sx = {},
  ...otherProps
}) {
  const Component = invert ? InvertBox : Box;
  return (
    <Component
      sx={{ backgroundColor: invert ? "background.paper" : "green.50", ...sx }}
      py={6}
      my={my}
      {...otherProps}
    >
      <Container>
        {overline && (
          <Typography
            display="block"
            variant="overline"
            color={invert ? "textSecondary" : "secondary.light"}
            pb={3}
          >
            {overline}
          </Typography>
        )}
        <Typography
          variant="blockquote"
          color={invert ? "textPrimary" : "secondary.light"}
        >
          {children}
        </Typography>
      </Container>
    </Component>
  );
}
